// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("X:\\xampp\\htdocs\\winnerwinnerchickendinnermke\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("X:\\xampp\\htdocs\\winnerwinnerchickendinnermke\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("X:\\xampp\\htdocs\\winnerwinnerchickendinnermke\\src\\pages\\menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-order-js": () => import("X:\\xampp\\htdocs\\winnerwinnerchickendinnermke\\src\\pages\\order.js" /* webpackChunkName: "component---src-pages-order-js" */)
}

